import React from 'react'
//import *as Styles from "../assets/css/ui-kit.css"
import logo from "../images/images-logos-unida-original.png"
import { Link } from "gatsby"

const Unidalogo = (props) => {
    const {link, width=140, height=32} = props

    if(link){
      return (
        <Link to={link} itemProp="url">
          <img
            src={logo}
            className='imageslogosunidaoriginal'
            width={width}
            height={height}
            alt="Església UNIDA De Terrassa"
          ></img>
        </Link>
      )
    }
    return (
      <img src={logo} className='imageslogosunidaoriginal' alt="Església UNIDA De Terrassa"></img>
    )
}

export default Unidalogo
