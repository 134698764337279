import React from 'react'
import UIKITheader from "../../components/ui-kit-header"
import UnidaLogo from "../../components/unida-logo"
import Favicon from "../../components/favicon"
import Title from "../../components/title"
import Rectangle from "../../components/rectangle"
import *as Styles from "../../assets/css/ui-kit.css"

const ColoursImages = (props) => {
    return (
        <div>
            <UIKITheader 
                title="UNIDA Components Overview & Ui Kit"
                subtitle="Visual overview of the Unida Design System styles, components, their states, statuses & sizes."
            />
            <div className="Coloursimages">
            <Title title="Corporate colours" type={2} color="black" weight={500} />
                <div className="bg-white Rectangle p-4">
                    <div className="container">
                    <Title title="Primary Colors" type={4} color="bordeaux" weight={300}/>
                        <div className="row gy-5">
                            <Rectangle className="bg-black height-75" title="Black #000000"/>
                            <Rectangle className="bg-green-dark height-75" title="Green-Dark #598048"/>
                            <Rectangle className="bg-green height-75" title="Green #96a88e"/>
                            <Rectangle className="bg-bordeaux-dark height-75" title="Bordeaux-Dark #6e161a"/>
                            <Rectangle className="bg-bordeaux height-75" title="Bordeaux #9a2323"/>
                            <Rectangle className="bg-grey-dark height-75" title="Grey-Dark #484848"/>
                            <Rectangle className="bg-beige height-75" title="Beige #ebe8e5"/>
                            <Rectangle className="bg-white height-75" title="White #FFFFFF" />
                        </div>
                    </div>
                </div>
                <div className='space'> 
                    <Title title="Images and SVG's" type={2} color="black" weight={500}/>
                    <div className='Rectangle bg-white p-4'>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-lg-4">
                                    <Title title="Logos" type={4} color="bordeaux" weight={300} />
                                    <UnidaLogo/>
                                </div>
                                <div className="col-12 col-lg-4 ">
                                    <Title title="Favicon" type={4} color="bordeaux" weight={300} />
                                    <Favicon/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ColoursImages