import React from 'react'
import *as Styles from "../assets/css/ui-kit.css"
import favicon from "../images/id-images-logos-unida-original.png"

const Favicon = (props) => {
    return (
      <img src={favicon} className="favicon" />
    )
}

export default Favicon
